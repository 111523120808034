import _ from 'lodash'

export default {
	data() {
		return {
			
		}
	},
	props: {
		text: {
			type: String
		},
		limit: {
			type: Number,
			default: 25
		},
		tooltip: {
			type: Boolean,
			default: true
		},
		separator: { //AS--> Mettre espace pour ne couper qu'entre les mots
			type: String,
			default: ''
		},
	},
	created(){
		
	},
	methods: {
		
	},
	computed:{
		isTooLong(){
			return this.text.length > this.limit
		},
		textShort(){
			return this.isTooLong ? _.truncate(this.text, { length: this.limit - 3, separator: this.separator }) : this.text
		},
		vtooltip(){
			if(this.isTooLong && this.tooltip){
				return {content: this.text, placement: 'bottom', offset: 0, delay: { show: 250, hide: 100 } }
			}else{
				return false
			}
		}
	},
	components:{
		
	}
}